import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './Home_Programs.css';

export default function Home_Programs(props) {
    const [activeTab, setActiveTab] = useState(-1);
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const programURL = "https://fibstudio-d1-prod.fibstudiosoporte.workers.dev/api/programs";//process.env.REACT_APP_HOME_PROGRAMS;

    const changeTab = (tab) => {
        // there is no programs loaded.
        if (programs === undefined || programs === null || programs.length == 0) {
            setActiveTab(-1);
            return;
        }

        if (tab < 0) {
            setActiveTab(programs[0].Id);
            return;
        }

        // if (tab > programs.length - 1) {
        //     setActiveTab(programs.length - 1);
        // }

        setActiveTab(tab);
        // if (programs !== undefined || programs !== null) {
        //     let selectedIndex = programs.findIndex(pr => pr.id == tab);
        //     setSelectedProgram(programs[selectedIndex]);
        // }
    }

    // Fetch card data function.
    const fetchPrograms = async () => {
        try {
            console.log("fetching from : ", programURL);
            const response = await fetch(
                programURL,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const responseWrapper = await response.json();
            console.log("Programs fetched: ", responseWrapper.data);
            if (responseWrapper.success) {
                setPrograms(responseWrapper.data);
                setLoading(false);
                setActiveTab(responseWrapper.data[0].Id);
                setError(false);
            } else {
                setPrograms([]);
                setLoading(false);
                setError(true);
            }
            
        } catch (err) {
            console.error(err);
            setPrograms([]);
            setLoading(false);
            setError(true);
        }
    };

    //use effect to fetch card values.
    useEffect(() => {
        console.log("use effect on programs triggered.");
        if ((programs === undefined || programs === null || programs.length == 0)&&!error) {
            fetchPrograms();
        }
    }, [programs]);

    let coverUI;
    let descriptionText;

    if (activeTab === -1) {
        coverUI = <></>;
        descriptionText = <></>;
    } else {
        let selectedIndex = programs.findIndex(pr => pr.Id == activeTab);
        let selectedProgram = programs[selectedIndex];

        if (selectedProgram.Is_Cover_Video) {
            coverUI = <ReactPlayer
                className='react-player videoPreview'
                url={selectedProgram.Cover_Url}
                width='80%'
                height='80%'
                controls={true}
            />;
        } else {
            coverUI = <img src={selectedProgram.Cover_Url} className='videoPreview' style={{ width: '80%', height: '80%' }}></img>;
        }
        descriptionText = <span className="font-josefin ">
            {selectedProgram.Description}
        </span>;
    }

    return (
        <div className='main-programs-container'>
            <div className="row top-bar m-b-8 m-l-2">
                <div className='col-1'>
                    <span className='font-josefin top-bar-left'><b>PROGRAMAS</b></span>
                </div>
                <div className='col-5'></div>
                <div className='col-6'>

                    <ul className='horizontalList'>
                        {programs.map((program) => (
                            <li className={'click-mouse ' + (activeTab === program.Id ? "active" : "")}>
                                <span className='font-josefin'
                                    onClick={() => { changeTab(program.Id) }}>{program.Name}</span>
                            </li>
                        ))}
                        <li>
                            <a className='font-josefin' href='/progs'>VER MÁS</a>
                        </li>
                    </ul>

                </div>

            </div>

            <div className='row program-description-container'>
                <div className='col-6'>
                    {coverUI}
                </div>

                <div className="col-6 pr">
                    <div className='program-description'>
                        {descriptionText}
                    </div>

                    <button className="btn-general font-josefin button-position">
                        <label><b>EMPEZAR</b></label>
                    </button>
                </div>
            </div>
            {/* <Home_Programs_Content selectedTab={activeTab} videoUrl={selectedProgram.cover_Url} programDescription={selectedProgram.cover_Url} /> */}

        </div>
    )
}